@import "_icons.scss";

@font-face {
  font-family: 'bague';
  src: url('/sites/all/themes/platanas/fonts/pfbaguesanspro-bold_0.eot');
  src: url('/sites/all/themes/platanas/fonts/pfbaguesanspro-bold_0.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-bold_0.woff2') format('woff2'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-bold_0.woff') format('woff'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-bold_0.ttf') format('truetype'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-bold_0.svg#bague') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'bague';
  src: url('/sites/all/themes/platanas/fonts/pfbaguesanspro-reg_0.eot');
  src: url('/sites/all/themes/platanas/fonts/pfbaguesanspro-reg_0.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-reg_0.woff2') format('woff2'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-reg_0.woff') format('woff'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-reg_0.ttf') format('truetype'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-reg_0.svg#bague') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'bague';
  src: url('/sites/all/themes/platanas/fonts/pfbaguesanspro-italic_0.eot');
  src: url('/sites/all/themes/platanas/fonts/pfbaguesanspro-italic_0.eot?#iefix') format('embedded-opentype'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-italic_0.woff2') format('woff2'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-italic_0.woff') format('woff'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-italic_0.ttf') format('truetype'),
  url('/sites/all/themes/platanas/fonts/pfbaguesanspro-italic_0.svg#bague') format('svg');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: 'icomoon';
  src:    url('/sites/all/themes/platanas/fonts/icomoon.eot');
  src:    url('/sites/all/themes/platanas/fonts/icomoon.eot#iefix') format('embedded-opentype'),
  url('/sites/all/themes/platanas/fonts/icomoon.ttf') format('truetype'),
  url('/sites/all/themes/platanas/fonts/icomoon.woff') format('woff'),
  url('/sites/all/themes/platanas/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'icomoon';
  src:    url('/sites/all/themes/platanas/fonts/icomoon.eot?5dsfv1');
  src:    url('/sites/all/themes/platanas/fonts/icomoon.eot?5dsfv1#iefix') format('embedded-opentype'),
  url('/sites/all/themes/platanas/fonts/icomoon.ttf?5dsfv1') format('truetype'),
  url('/sites/all/themes/platanas/fonts/icomoon.woff?5dsfv1') format('woff'),
  url('/sites/all/themes/platanas/fonts/icomoon.svg?5dsfv1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-x:before {
  content: "\e907";
}
.icon-twitter:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-mail:before {
  content: "\e90c";
}
.icon-check:before {
  content: "\e901";
}
.icon-chevron-thin-right:before {
  content: "\e902";
}
.icon-chevron-small-right:before {
  content: "\e903";
}
.icon-chevron-thin-left:before {
  content: "\e904";
}
.icon-chevron-small-left:before {
  content: "\e905";
}
.icon-arrow_drop_down:before {
  content: "\e906";
}
.icon-cart:before {
  content: "\e908";
}
.icon-search:before {
  content: "\e909";
}
.icon-user:before {
  content: "\e90a";
}

.icon-refresh:before {
  content: "\e94b";
}

