@mixin icon($image, $wbox-padding: 0, $hbox-padding: 0) {
  @include icons-sprite($image);
  width: icons-sprite-width($image) + $wbox-padding;
  height: icons-sprite-height($image) + $hbox-padding;
}

@mixin ph-selectbox {
  border: 1px solid #e7e7e7;
  display: block;
  height: 40px;
  padding: 0;
  margin: 0;
  background: #fff;
  max-width: 100%;
  min-width: 100%;
  float: none;

  @include breakpoint($tab) {
    min-width: 0;
    float: right;
  }

  & > span {
    line-height: 40px;
    padding: 0 45px 0 10px;
    font-size: 16px;
    color: #333333;
    font-weight: $normal;
    min-width: 100%;
    max-width: 100%;
    background: #fff;
    @include icon(selectbox, arrows, after, auto, 3px);
  }
}

@mixin font($size, $height: $size, $weight: normal) {
  font-size: $size;
  line-height: $height;
  font-weight: $weight;
}

@mixin border-arrow($direction, $size, $color) {
  width: 0;
  height: 0;

  @if $direction == "top" {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }

  @if $direction == "down" {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }

  @if $direction == "right" {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  }

  @if $direction == "left" {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  }
}




@mixin placeholder-text($color, $font-size) {
  &:-moz-placeholder {
    color: $color;
    font-size: $font-size;
  }

  &:-ms-input-placeholder {
    color: $color;
    font-size: $font-size;
  }

  &::-webkit-input-placeholder {
    color: $color;
    font-size: $font-size;
  }
}

@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin pseudo($display: block) {
  content: '';
  display: $display;
  position: absolute;
}


@mixin align($position) {
  @if $position == "vert" {
    top: 0;
    bottom: 0;
    margin: auto;
  }

  @if $position == "horz" {
    left: 0;
    right: 0;
    margin: auto;
  }

  @if $position == "both" {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

@mixin full-width($color) {
  position: relative;

  &:before, &:after {
    @include pseudo;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }

  &:before {
    background: $color;
    left: -100%;
  }

  &:after {
    background: $color;
    right: -100%;
  }
}
