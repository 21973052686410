/*******************************************************************************
 * simple.layout.scss
 *
 * Styling of the "simple" layout demonstrating the Compass grid extension Susy
 * and building mobile first layouts.
 ******************************************************************************/
@import "compass";
@import "breakpoint";
@import "susy";

// Susy Variables

// Set consistent vertical and horizontal spacing units.
$vert-spacing-unit: 15px;
$horz-spacing-unit: 1em;

// Define Susy grid variables mobile first.
$susy: (
        flow: ltr,
        math: static,
        output: float,
        gutter-position: after,
        container: 945px,
        container-position: center,
        columns: 12,
        gutters: 0.55,
        column-width: 51px,
        global-box-sizing: content-box,
        last-flow: to,
        //debug: (image: show)
);


.l-region--nav-top,
.container,
.l-content,
.l-region--post-content,
.l-region--pre-content,
.l-region--three-grid,
.l-region--footer{
  @include container;
}

.l-page,
.l-footer{
  min-width: 980px;
  overflow-x: hidden;
}

.categories-front{
}



.l-content .node{
  float: left;
}