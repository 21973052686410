.category-icon{
  height: 90px;
  width: 100%;
  background-size: cover;
}

.svg-thermansi{
  background:  url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve"><circle fill="%23FE3341" cx="29.3" cy="47.6" r="3.3"/><g><path fill="%237A7A7A" d="M57.2,20c-0.7,0-1.2,0.6-1.2,1.2v3.1H2.5v-3.1c0-0.7-0.6-1.2-1.2-1.2S0,20.6,0,21.2v52.5
		C0,74.4,0.6,75,1.2,75s1.2-0.6,1.2-1.2v-3.1H56v3.1c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2V21.2C58.4,20.6,57.9,20,57.2,20z
		 M2.5,61.9h14.9c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2H2.5v-1.9h13.3c0.7,0,1.2-0.6,1.2-1.2S16.5,55,15.8,55H2.5v-1.9h16.9
		c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2H2.5v-1.9h8.9c0.7,0,1.2-0.6,1.2-1.2s-0.6-1.2-1.2-1.2H2.5v-1.9h9.5
		c0.7,0,1.2-0.6,1.2-1.2s-0.6-1.2-1.2-1.2H2.5V40h11.3c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2H2.5v-1.9h22.6
		c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2H2.5v-1.9H56v1.9H40.6c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2H56v1.9H42.1
		c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2H56v1.9H38.5c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2H56v1.9h-9.5
		c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2H56v1.9H46c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2h10V55H44.2
		c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2H56v1.9H32.4c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2H56v1.9H2.5V61.9z M56,26.8v1.9
		H2.5v-1.9H56z M2.5,68.2v-1.9H56v1.9H2.5z"/><path fill="%237A7A7A" d="M31.6,51.9l6.3,6.3c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4c2.9-2.9,4.4-6.7,4.4-10.7
		c0-0.7-0.6-1.2-1.2-1.2h-9c-0.1-0.5-0.3-1-0.6-1.4l6.3-6.3c0.5-0.5,0.5-1.3,0-1.7c-2.9-2.9-6.7-4.4-10.7-4.4
		c-0.7,0-1.2,0.6-1.2,1.2v9c-0.5,0.1-0.9,0.3-1.4,0.6L20,36.8c-0.2-0.2-0.5-0.4-0.9-0.4s-0.6,0.1-0.9,0.4c-2.9,2.9-4.4,6.7-4.4,10.7
		c0,0.7,0.6,1.2,1.2,1.2h9c0.1,0.5,0.3,0.9,0.6,1.4l-6.3,6.3c-0.5,0.5-0.5,1.3,0,1.7c2.9,2.9,6.7,4.4,10.7,4.4
		c0.7,0,1.2-0.6,1.2-1.2v-9C30.7,52.3,31.1,52.1,31.6,51.9z M38.7,55.5l-5.4-5.4c0.3-0.4,0.5-0.9,0.6-1.4h7.7
		C41.3,51.2,40.3,53.6,38.7,55.5z M37,37.7l-5.4,5.4c-0.4-0.3-0.9-0.5-1.4-0.6v-7.7C32.7,35.1,35,36.1,37,37.7z M28.9,44.9
		c0.7,0,1.4,0.3,1.8,0.8c0.5,0.5,0.8,1.2,0.8,1.8s-0.3,1.4-0.8,1.8c-0.5,0.5-1.2,0.8-1.8,0.8s-1.4-0.3-1.8-0.8
		c-0.5-0.5-0.8-1.2-0.8-1.8s0.3-1.4,0.8-1.8C27.6,45.2,28.2,44.9,28.9,44.9z M19.2,39.5l5.4,5.4c-0.3,0.4-0.5,0.9-0.6,1.4h-7.7
		C16.6,43.8,17.6,41.4,19.2,39.5z M20.9,57.3l5.4-5.4c0.4,0.3,0.9,0.5,1.4,0.6v7.7C25.2,59.9,22.9,58.9,20.9,57.3z"/></g></svg>')  no-repeat;
}
.svg-accessories{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve"><polygon fill="%23FE3341" points="1.3,29.3 27.8,22.4 53.9,29.4 53.9,34 27.6,27.1 0.8,34 "/><g><path fill="%237A7A7A" d="M51.5,46.1c0-1.4-1.2-2.6-2.6-2.6h-1.7c-1,0-1.8,0.5-2.2,1.3L42.8,40c-0.9-2-2.7-3.4-4.8-3.9
		c-3.5-0.8-17.3-0.8-20.7,0c-2.1,0.5-3.9,2-4.8,3.9l-2.2,4.8C9.8,44,9,43.5,8,43.5H6.3c-1.4,0-2.6,1.2-2.6,2.6c0,1.3,1,2.4,2.3,2.6
		l2,0.2l-2,2c-1.2,1.2-1.7,2.8-1.5,4.5L5.3,61c0.2,1.5,0.9,2.7,2,3.6v2.4c0,1.6,1.3,2.9,2.9,2.9h4.5c1.6,0,2.9-1.3,2.9-2.9v-1h19.8
		v1c0,1.6,1.3,2.9,2.9,2.9H45c1.6,0,2.9-1.3,2.9-2.9v-2.4c1.1-0.9,1.8-2.2,2-3.6l0.8-5.6c0.2-1.6-0.3-3.3-1.5-4.5l-2-2l2-0.2
		C50.5,48.5,51.5,47.4,51.5,46.1z M17.7,38.3c3.1-0.7,16.6-0.7,19.7,0c0.5,0.1,0.9,0.3,1.3,0.5c-4.9-0.6-17.5-0.6-22.4,0
		C16.8,38.5,17.3,38.4,17.7,38.3z M14.1,41.7c0.5-0.3,1.1-0.4,1.7-0.6c3.9-0.7,19.8-0.7,23.6,0c0.6,0.1,1.2,0.3,1.7,0.6l2.6,5.8
		H11.5L14.1,41.7z M5.9,46.1c0-0.2,0.2-0.4,0.4-0.4H8c0.2,0,0.4,0.2,0.4,0.4v0.6l-2.1-0.3C6.1,46.4,5.9,46.3,5.9,46.1z M44.1,63.8
		h-33c-1.6,0-3.1-1.1-3.5-2.7h40.1C47.2,62.7,45.8,63.8,44.1,63.8z M41.3,58.9l-0.8-2.5h7.9L48,58.9H41.3z M38.1,56.4l0.8,2.5h-0.8
		l-0.8-2.5H38.1z M35,56.4l0.8,2.5H19.5l0.8-2.5H35z M17.1,58.9h-0.8l0.8-2.5h0.8L17.1,58.9z M14,58.9H7.2l-0.4-2.5h7.9L14,58.9z
		 M15.5,67.1c0,0.4-0.3,0.7-0.7,0.7h-4.5c-0.4,0-0.7-0.3-0.7-0.7v-1.2c0.5,0.1,1,0.2,1.6,0.2h4.4V67.1z M45.7,67.1
		c0,0.4-0.3,0.7-0.7,0.7h-4.5c-0.4,0-0.7-0.3-0.7-0.7v-1h4.4c0.5,0,1.1-0.1,1.6-0.2L45.7,67.1L45.7,67.1z M48.5,54.1H6.7
		c0.1-0.6,0.4-1.2,0.8-1.6l2.8-2.8h34.4l2.8,2.8C48.1,52.9,48.4,53.5,48.5,54.1z M48.9,46.5l-2.1,0.3v-0.6c0-0.2,0.2-0.4,0.4-0.4
		h1.7c0.2,0,0.4,0.2,0.4,0.4C49.3,46.3,49.1,46.4,48.9,46.5z"/>
  <path fill="%237A7A7A" d="M54.4,28.8l-26.5-7.3c-0.2-0.1-0.4-0.1-0.6,0L0.8,28.8C0.3,28.9,0,29.4,0,29.9v42.5c0,0.6,0.5,1.1,1.1,1.1
		h53c0.6,0,1.1-0.5,1.1-1.1V29.9C55.2,29.4,54.9,28.9,54.4,28.8z M27.6,23.8l25.4,7v2.1l-25.1-6.9c-0.2-0.1-0.4-0.1-0.6,0L2.2,32.8
		v-2.1L27.6,23.8z M2.2,71.3V35.1l25.4-7l25.4,7v36.2H2.2z"/></g></svg>') no-repeat;
}
.svg-baseisKinitira{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve">
<rect x="2" y="51.5" fill="%23FE3341" width="44.2" height="4.2"/><g><path fill="%237A7A7A" d="M46.8,49.7h-3c0-0.2,0-0.4,0-0.5v-2.3c1-0.9,1.7-2.1,1.9-3.6l0.8-5.4c0.2-1.6-0.3-3.3-1.5-4.5l-1.7-1.7
		l1.6-0.2c1.3-0.2,2.4-1.3,2.4-2.7c0-1.5-1.2-2.7-2.7-2.7H43c-0.9,0-1.7,0.4-2.1,1.1l-2-4.3c-0.9-2-2.7-3.4-4.8-3.9
		c-3.4-0.8-16.8-0.8-20.1,0c-2.1,0.5-3.9,1.9-4.8,3.9l-2,4.3c-0.5-0.7-1.3-1.1-2.1-1.1H3.4c-1.5,0-2.7,1.2-2.7,2.7
		c0,1.4,1,2.5,2.4,2.7l1.6,0.2L3,33.5c-1.2,1.2-1.7,2.8-1.5,4.5l0.8,5.4c0.2,1.4,0.9,2.7,1.9,3.6v2.3c0,0.2,0,0.4,0,0.5h-3
		C0.6,49.7,0,50.3,0,51v4.8C0,56.4,0.6,57,1.2,57h17v3.5c0,0.7,0.6,1.2,1.2,1.2h1.3v13.5c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2
		V61.8h1.4v13.5c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2V61.8h1.3c0.7,0,1.2-0.6,1.2-1.2V57h17c0.7,0,1.2-0.6,1.2-1.2V51
		C48.1,50.3,47.5,49.7,46.8,49.7z M41.4,49.2c0,0.3-0.2,0.5-0.5,0.5h-4.4c-0.3,0-0.5-0.2-0.5-0.5v-0.8H40c0.5,0,0.9-0.1,1.4-0.2
		L41.4,49.2L41.4,49.2z M4.9,43.6h38.3c-0.5,1.3-1.7,2.3-3.2,2.3h-32C6.6,45.9,5.3,44.9,4.9,43.6z M7.4,32.5h33.2l2.7,2.7
		c0.4,0.4,0.6,0.8,0.7,1.3H4c0.1-0.5,0.4-1,0.7-1.3L7.4,32.5z M37.3,41.1L36.7,39h7.2l-0.3,2.1H37.3z M34.1,39l0.7,2.1H13.3L14,39
		H34.1z M11.4,39l-0.7,2.1H4.5L4.2,39H11.4z M14.6,48.4h18.9v0.8c0,0.2,0,0.4,0,0.5h-19c0-0.2,0-0.4,0-0.5V48.4z M42.8,28.9
		c0-0.1,0.1-0.2,0.2-0.2h1.6c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2l-1.9,0.2L42.8,28.9L42.8,28.9z M11.4,24
		c0.6-1.3,1.7-2.2,3.1-2.5c3-0.7,16-0.7,19,0c1.3,0.3,2.5,1.2,3.1,2.5l2.8,6.1H8.7L11.4,24z M3.2,28.9c0-0.1,0.1-0.2,0.2-0.2h1.6
		c0.1,0,0.2,0.1,0.2,0.2v0.4l-1.9-0.2C3.3,29.1,3.2,29,3.2,28.9z M6.7,49.2v-1c0.4,0.1,0.9,0.2,1.4,0.2h4.1v0.8
		c0,0.3-0.2,0.5-0.5,0.5H7.2C6.9,49.7,6.7,49.5,6.7,49.2z M27.3,59.3h-6.6V57h6.6L27.3,59.3z M45.6,54.5H2.5v-2.3h43.1V54.5z"/>
	<path fill="%237A7A7A" d="M41.3,58.8c-0.2-0.4-0.6-0.6-1.1-0.6c-0.4,0-0.8,0.2-1.1,0.6l-4.7,8.1c-0.2,0.4-0.2,0.9,0,1.2
		c0.2,0.4,0.6,0.6,1.1,0.6h1.2v6.5c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2v-7.7c0-0.7-0.6-1.2-1.2-1.2h-0.3l2.6-4.4l2.6,4.4
		h-0.3c-0.7,0-1.2,0.6-1.2,1.2v7.7c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2v-6.5H45c0.4,0,0.8-0.2,1.1-0.6
		c0.2-0.4,0.2-0.9,0-1.2L41.3,58.8z"/>
	<path fill="%237A7A7A" d="M9,58.8c-0.2-0.4-0.6-0.6-1.1-0.6c-0.4,0-0.8,0.2-1.1,0.6l-4.7,8.1c-0.2,0.4-0.2,0.9,0,1.2
		c0.2,0.4,0.6,0.6,1.1,0.6h1.2v6.5c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v-7.7c0-0.7-0.6-1.2-1.2-1.2H5.4L8,61.8l2.6,4.4h-0.3
		c-0.7,0-1.2,0.6-1.2,1.2v7.7c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v-6.5h1.2c0.4,0,0.8-0.2,1.1-0.6c0.2-0.4,0.2-0.9,0-1.2L9,58.8z
		"/></g></svg>') no-repeat;
}
.svg-brake{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve">
<g>
	<path fill="%237A7A7A" d="M54.4,44.5c-1.5-6.5-5.4-12.1-11-15.7c-3.5-2.2-7.5-3.5-11.6-3.8c0-0.4-0.1-0.8-0.3-1.2
		c-0.6-1.8-2.2-3-4-3.2L23,20.1c-2.5-0.3-5,0.6-6.7,2.4L2.4,36.2C0.7,38-0.2,40.5,0,43l0.5,4.5c0.2,1.9,1.4,3.4,3.2,4
		c0.4,0.1,0.8,0.2,1.2,0.3c0.6,7.9,4.9,15,11.6,19.3c4.2,2.6,8.8,3.9,13.4,3.9c8.3,0,16.4-4.1,21.2-11.6
		C54.7,57.7,55.8,51,54.4,44.5z M10.9,48.2l5.6-5.6l1.5-1.5l3.6-3.6c0.2,0.2,1.6-1.6,1.6-1.6l5-5c4.2-0.4,8.3,0.6,11.9,2.9
		C49,39.4,51.7,51.3,46,60.2c-2.7,4.3-7,7.3-12,8.4c-5,1.1-10.1,0.2-14.4-2.5c-3.1-1.9-5.5-4.7-7-8C11.2,55,10.6,51.6,10.9,48.2z
		 M2.8,47.3l-0.5-4.5c-0.2-1.8,0.4-3.6,1.7-4.9l13.8-13.8c1.3-1.3,3.1-1.9,4.9-1.7l4.5,0.5c1,0.1,1.8,0.7,2.1,1.7
		c0.3,0.9,0.1,1.9-0.6,2.6L7.1,48.8c-0.7,0.7-1.7,0.9-2.6,0.6C3.5,49.1,2.9,48.3,2.8,47.3z M49.1,62.1C44.8,69,37.4,72.7,29.9,72.7
		c-4.2,0-8.4-1.1-12.2-3.6c-6.1-3.9-10-10.5-10.5-17.7c0.5-0.2,1-0.5,1.4-0.9c0.1,2.9,0.8,5.8,2,8.4c1.7,3.7,4.4,6.8,7.9,8.9
		c3.5,2.2,7.4,3.3,11.4,3.3c1.6,0,3.1-0.2,4.7-0.5c5.6-1.2,10.3-4.6,13.4-9.4c6.3-10,3.4-23.2-6.6-29.5c-3.2-2.1-7-3.2-10.8-3.3
		c0.4-0.4,0.7-0.9,0.9-1.4c3.8,0.3,7.5,1.5,10.7,3.5c5.1,3.3,8.7,8.3,10,14.3C53.5,50.9,52.4,57,49.1,62.1z"/>
	<path fill="%237A7A7A" d="M23.7,59.6c1.9,1.2,4,1.8,6.1,1.8c0.8,0,1.7-0.1,2.5-0.3c3-0.7,5.5-2.5,7.2-5c1.6-2.6,2.2-5.7,1.5-8.7
		c-0.7-3-2.5-5.5-5-7.2c-5.3-3.4-12.4-1.8-15.8,3.5C16.8,49.1,18.4,56.2,23.7,59.6z M22.1,45c1.7-2.8,4.7-4.3,7.8-4.3
		c1.7,0,3.4,0.5,4.9,1.4c2.1,1.3,3.5,3.4,4,5.8c0.5,2.4,0.1,4.9-1.2,6.9c-1.3,2.1-3.4,3.5-5.8,4C29.5,59.4,27,59,25,57.7
		C20.7,55,19.4,49.3,22.1,45z"/>
	<path fill="%23FE3341" d="M44,56c0.1,0,0.2,0.1,0.4,0.1c0.5,0,0.9-0.3,1.1-0.8c0.2-0.6-0.1-1.2-0.7-1.5c-0.6-0.2-1.2,0.1-1.5,0.7
		C43.1,55.1,43.4,55.8,44,56z"/>
	<path fill="%23FE3341" d="M42.2,42c0.2,0,0.5-0.1,0.7-0.2c0.5-0.4,0.6-1.1,0.2-1.6c-0.4-0.5-1.1-0.6-1.6-0.2
		c-0.5,0.4-0.6,1.1-0.2,1.6C41.6,41.8,41.9,42,42.2,42z"/>
	<path fill="%23FE3341" d="M45.1,48.7c0.1,0,0.1,0,0.2,0c0.6-0.1,1.1-0.7,1-1.3c-0.1-0.6-0.7-1.1-1.3-1c-0.6,0.1-1.1,0.7-1,1.3
		C44,48.3,44.5,48.7,45.1,48.7z"/>
	<path fill="%23FE3341" d="M26.9,63.8c-0.6-0.1-1.2,0.3-1.4,0.9c-0.1,0.6,0.3,1.2,0.9,1.4c0.1,0,0.2,0,0.2,0c0.5,0,1-0.4,1.1-0.9
		C28,64.5,27.6,63.9,26.9,63.8z"/>
	<path fill="%23FE3341" d="M33.7,63.6c-0.6,0.2-1,0.8-0.8,1.4c0.1,0.5,0.6,0.8,1.1,0.8c0.1,0,0.2,0,0.3,0c0.6-0.2,1-0.8,0.8-1.4
		C35,63.8,34.3,63.4,33.7,63.6z"/>
	<path fill="%23FE3341" d="M39.6,60.2c-0.5,0.4-0.5,1.2,0,1.6c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.3c0.5-0.4,0.5-1.2,0-1.6
		C40.8,59.8,40.1,59.8,39.6,60.2z"/>
	<path fill="%23FE3341" d="M20.8,60.8c-0.5-0.4-1.2-0.3-1.6,0.1c-0.4,0.5-0.3,1.2,0.1,1.6c0.2,0.2,0.5,0.3,0.7,0.3
		c0.3,0,0.7-0.1,0.9-0.4C21.4,61.9,21.3,61.2,20.8,60.8z"/>
	<path fill="%23FE3341" d="M36.1,37.1C36.1,37.1,36.1,37.1,36.1,37.1C36.1,37.2,36.1,37.2,36.1,37.1c0.2,0.1,0.3,0.1,0.5,0.1
		c0.4,0,0.8-0.2,1-0.6c0.3-0.6,0-1.3-0.5-1.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.6-0.3-1.3,0-1.5,0.5C35.3,36.2,35.5,36.9,36.1,37.1z"/>
	<path fill="%23FE3341" d="M15.3,54.7c-0.6,0.2-0.9,0.9-0.6,1.5c0.2,0.4,0.6,0.7,1.1,0.7c0.1,0,0.3,0,0.4-0.1c0.6-0.2,0.9-0.9,0.6-1.5
		C16.6,54.8,15.9,54.5,15.3,54.7z"/>
	<path fill="%23FE3341" d="M14.5,49.7c0,0,0.1,0,0.1,0c0.6,0,1.1-0.4,1.1-1c0.1-0.6-0.4-1.2-1-1.2c-0.6-0.1-1.2,0.4-1.2,1
		C13.4,49.1,13.9,49.6,14.5,49.7z"/>
	<path fill="%23FE3341" d="M29.4,35.7C29.4,35.7,29.4,35.7,29.4,35.7c0.7,0,1.2-0.5,1.1-1.2c0-0.6-0.5-1.1-1.2-1.1
		c-0.6,0-1.1,0.5-1.1,1.2C28.3,35.2,28.8,35.7,29.4,35.7z"/>
	<path fill="%237A7A7A" d="M25.7,56.5c1.3,0.8,2.7,1.2,4.2,1.2c0.6,0,1.2-0.1,1.7-0.2c2-0.5,3.8-1.7,4.9-3.5c1.1-1.8,1.5-3.9,1-5.9
		c-0.5-2-1.7-3.8-3.5-4.9h0c-3.7-2.3-8.5-1.2-10.8,2.4C20.9,49.4,22,54.2,25.7,56.5z M25.2,46.9c1.1-1.7,2.9-2.6,4.7-2.6
		c1,0,2.1,0.3,3,0.9c1.3,0.8,2.1,2,2.5,3.5s0.1,2.9-0.7,4.2c-0.8,1.3-2,2.1-3.5,2.5c-1.5,0.3-2.9,0.1-4.2-0.7
		C24.3,53,23.6,49.5,25.2,46.9z"/>
	<path fill="%237A7A7A" d="M8.6,43.3c0.8,0,1.5-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1c0-0.8-0.3-1.5-0.9-2.1c-0.6-0.6-1.3-0.9-2.1-0.9
		c-0.8,0-1.5,0.3-2.1,0.9c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.5,0.9,2.1C7,43,7.8,43.3,8.6,43.3z M8.1,39.9
		c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.5C8.9,40.9,8.7,41,8.6,41
		c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5C7.9,40.2,8,40,8.1,39.9z"/>
	<path fill="%237A7A7A" d="M20.3,31.5c0.8,0,1.5-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1S23,27,22.4,26.5c-0.6-0.6-1.3-0.9-2.1-0.9
		c-0.8,0-1.5,0.3-2.1,0.9c-0.6,0.6-0.9,1.3-0.9,2.1s0.3,1.5,0.9,2.1S19.5,31.5,20.3,31.5z M19.9,28.1c0.1-0.1,0.3-0.2,0.5-0.2
		c0.2,0,0.3,0.1,0.5,0.2s0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.3-0.1-0.5-0.2s-0.2-0.3-0.2-0.5
		S19.7,28.2,19.9,28.1z"/>
	<path fill="%23FE3341" d="M21.7,37.5c0.2,0.1,0.4,0.2,0.7,0.2c0,0,0,0,0,0c0.6,0,1.1-0.5,1.1-1.2c0-0.2-0.1-0.5-0.3-0.6L21.7,37.5z"
		/>
	<path fill="%23FE3341" d="M16.7,42.5c0.2,0.1,0.4,0.2,0.7,0.2c0,0,0,0,0,0c0.6,0,1.1-0.5,1.1-1.2c0-0.2-0.1-0.5-0.3-0.6L16.7,42.5z"
		/></g></svg>') no-repeat;
}
.svg-direction{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve">
<ellipse fill="%23FE3341" cx="22.6" cy="25.6" rx="3.1" ry="2.8"/>
<path fill="%237A7A7A" d="M32.7,38.7l4.4,1.6c1.3,0.5,2.7-0.2,3.1-1.5l4-11.4c0.5-1.3-0.2-2.7-1.5-3.1l-4.4-1.6
	c-1.3-0.5-2.7,0.2-3.1,1.5l-1.6,4.6l-7.2-2.5c0.1-1.8-1-3.6-2.8-4.3c-1.8-0.6-3.8,0-4.9,1.5L11.6,21l1.6-4.6
	c0.5-1.3-0.2-2.7-1.5-3.1l-4.4-1.6c-1.3-0.5-2.7,0.2-3.1,1.5l-4,11.4c-0.5,1.3,0.2,2.7,1.5,3.1l4.4,1.6c1.3,0.5,2.7-0.2,3.1-1.5
	l1.6-4.6l7.2,2.5c-0.1,1.5,0.6,2.9,1.8,3.7c0,0,0.7,0.4,0.9,0.5c0.1,0,0.3,0,0.4,0.1c0,0,0.4,0,0.4,0.1v19.3c-2,0.7-3.1,2.8-3.1,5.4
	c0,2.6,1.1,4.8,3.1,5.4v4.7c-2,0.4-2.9,1.1-3.3,3.1h-7.7v-5c0-1.4-1-2-2.4-2H3.4c-1.4,0-2.9,0.6-2.9,2V75c0,1.4,1.5,3,2.9,3h4.7
	c1.4,0,2.4-1.6,2.4-3v-5h7.7c0.5,2,2.1,3.1,4.1,3.1c1.9,0,3.6-1.1,4.1-3.1h7.2v5c0,1.4,1.5,3,2.9,3h4.7c1.4,0,2.4-1.6,2.4-3V63
	c0-1.4-1-2-2.4-2h-4.7c-1.4,0-2.9,0.6-2.9,2v5h-7.2c-0.4-2-1.8-2.7-2.8-3.1v-4.7c2-0.7,3.1-2.8,3.1-5.4c0-2.6-1.1-4.8-3.1-5.4V30.1
	c0,0,0.1-0.1,0.1-0.1c0.8-0.3,1.6-0.8,2.1-1.6l7.1,2.5l-1.6,4.6C30.8,36.8,31.5,38.2,32.7,38.7z M7.1,27L7.1,27
	c0,0.1-0.1,0.1-0.2,0.1l-4.4-1.6c-0.1,0-0.1-0.1-0.1-0.2l4-11.4c0-0.1,0.1-0.1,0.2-0.1l4.4,1.6c0.1,0,0.1,0.1,0.1,0.2L7.1,27z
	 M8.1,75H3.4c-0.1,0,0.1,0.1,0.1,0V63c0-0.1-0.2,0-0.1,0h4.7c0.1,0,0.4-0.1,0.4,0V75l0,0C8.5,75.1,8.2,75,8.1,75z M41.5,74.9
	c0,0.1-0.1,0.1-0.1,0.1h-4.7c-0.1,0-0.1-0.1-0.1-0.1V63.1c0-0.1,0.1-0.1,0.1-0.1h4.7c0.1,0,0.1,0.1,0.1,0.1V74.9z M24.2,69
	c0,1.1-0.9,1.9-1.9,1.9s-1.9-0.9-1.9-1.9s0.9-1.9,1.9-1.9S24.2,67.9,24.2,69z M24.2,54.8c0,2-1,3.3-1.9,3.3c-0.9,0-1.9-1.4-1.9-3.3
	c0-2,1-3.3,1.9-3.3S24.2,52.8,24.2,54.8z M24.1,26.6c-0.4,1-1.5,1.5-2.5,1.2c-1-0.4-1.5-1.5-1.2-2.5c0.4-1,1.5-1.5,2.5-1.2
	C23.9,24.5,24.4,25.6,24.1,26.6z M37.4,25c0-0.1,0.1-0.1,0.2-0.1l4.4,1.6c0.1,0,0.1,0.1,0.1,0.2l-4,11.4c0,0.1-0.1,0.1-0.2,0.1
	l-4.4-1.6c-0.1,0-0.1-0.1-0.1-0.2L37.4,25z"/>
</svg>')  no-repeat;
}
.svg-fanopoiias{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve">
<polygon fill="%23FE3341" points="3.2,57.2 6.2,62.9 58.4,63.1 60.8,58.2 18,57.1 "/>
<path fill="%237A7A7A" d="M57.8,42.1l3.3-0.4c1.6-0.2,2.8-1.6,2.8-3.2c0-1.8-1.4-3.2-3.2-3.2h-2.3c-1.4,0-2.6,0.9-3,2.1l-3.2-7
	c-1.2-2.6-3.5-4.5-6.3-5.1c-4.7-1.1-23.2-1.1-27.9,0c-2.8,0.6-5.1,2.6-6.3,5.1l-3.2,7c-0.5-1.2-1.6-2.1-3-2.1H3.2
	c-1.8,0-3.2,1.4-3.2,3.2c0,1.6,1.2,3,2.8,3.2l3.3,0.4L3,45.2c-1.5,1.5-2.2,3.7-1.9,5.8l1.1,7.5c0.3,1.9,1.3,3.6,2.7,4.8v3.4
	c0,2,1.7,3.7,3.7,3.7h6.1c2,0,3.7-1.7,3.7-3.7v-1.7h27.3v1.7c0,2,1.7,3.7,3.7,3.7h6.1c2,0,3.7-1.7,3.7-3.7v-3.4
	c1.4-1.2,2.4-2.9,2.7-4.8l1.1-7.5c0.3-2.1-0.4-4.3-1.9-5.8L57.8,42.1z M54.2,62.7H9.7c-2.5,0-4.6-1.8-5.1-4.2h54.7
	C58.8,60.9,56.7,62.7,54.2,62.7z M46.4,52.1l1.2,4h-1.7l-1.2-4H46.4z M42.1,52.1l1.2,4H20.6l1.2-4H42.1z M18,56.1h-1.7l1.2-4h1.7
	L18,56.1z M13.8,56.1H4.2l-0.6-4H15L13.8,56.1z M50.2,56.1l-1.2-4h11.3l-0.6,4H50.2z M57.6,38.5c0-0.4,0.4-0.8,0.8-0.8h2.3
	c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.3,0.7-0.7,0.8l-3.2,0.4L57.6,38.5L57.6,38.5z M18.6,27.7c4.3-1,22.5-1,26.8,0c1,0.2,2,0.7,2.8,1.4
	c-5.8-1-26.6-1-32.4,0C16.6,28.4,17.5,27.9,18.6,27.7z M13.5,32.4c0.8-0.4,1.6-0.7,2.4-0.8c5.2-1,26.8-1,32,0
	c0.9,0.2,1.7,0.4,2.4,0.8l3.8,8.3H9.7L13.5,32.4z M2.4,38.5c0-0.4,0.4-0.8,0.8-0.8h2.3c0.4,0,0.8,0.4,0.8,0.8v1.2l-3.2-0.4
	C2.7,39.3,2.4,38.9,2.4,38.5z M8.6,43.1h46.7l3.9,3.9c0.7,0.7,1.2,1.7,1.3,2.7h-57c0.1-1,0.5-2,1.3-2.7L8.6,43.1z M15.9,66.8
	c0,0.7-0.6,1.2-1.2,1.2H8.5c-0.7,0-1.2-0.6-1.2-1.2v-2.1C8,65,8.8,65.2,9.7,65.2h6.2V66.8z M56.6,66.8c0,0.7-0.6,1.2-1.2,1.2h-6.1
	c-0.7,0-1.2-0.6-1.2-1.2v-1.7h6.2c0.8,0,1.6-0.1,2.4-0.4L56.6,66.8L56.6,66.8z"/></svg>') no-repeat;
}
.svg-electricalparts{

  background: url('data:image/svg+xml;utf8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve"><g><path fill="%237A7A7A" d="M34.6,33.3c-9.8,0-17.8,8-17.8,17.8c0,9.8,8,17.8,17.8,17.8s17.8-8,17.8-17.8
		C52.4,41.3,44.4,33.3,34.6,33.3z M34.6,66.3c-6.6,0-12.2-4.2-14.3-10.1l1.5-0.4c0.7-0.2,1.1-0.9,0.9-1.6c-0.2-0.7-0.9-1.1-1.6-0.9
		l-1.5,0.4c-0.2-0.9-0.2-1.8-0.2-2.7c0-0.9,0.1-1.8,0.2-2.7l1.5,0.4c0.1,0,0.2,0,0.3,0c0.6,0,1.1-0.4,1.2-0.9
		c0.2-0.7-0.2-1.4-0.9-1.6l-1.5-0.4c0.6-1.7,1.5-3.3,2.7-4.7l1.1,1.1c0.2,0.2,0.6,0.4,0.9,0.4s0.7-0.1,0.9-0.4
		c0.5-0.5,0.5-1.3,0-1.8l-1.1-1.1c1.4-1.2,2.9-2.1,4.7-2.7l0.4,1.5c0.2,0.6,0.7,0.9,1.2,0.9c0.1,0,0.2,0,0.3,0
		c0.7-0.2,1.1-0.9,0.9-1.6l-0.4-1.5c0.9-0.2,1.8-0.2,2.7-0.2c0.9,0,1.8,0.1,2.7,0.2l-0.4,1.5c-0.2,0.7,0.2,1.4,0.9,1.6
		c0.1,0,0.2,0,0.3,0c0.6,0,1.1-0.4,1.2-0.9l0.4-1.5c1.7,0.6,3.3,1.5,4.7,2.7l-1.1,1.1c-0.5,0.5-0.5,1.3,0,1.8
		c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4l1.1-1.1c1.2,1.4,2.1,2.9,2.7,4.7l-1.5,0.4c-0.7,0.2-1.1,0.9-0.9,1.6
		c0.2,0.6,0.7,0.9,1.2,0.9c0.1,0,0.2,0,0.3,0l1.5-0.4c0.2,0.9,0.2,1.8,0.2,2.7s-0.1,1.8-0.2,2.7l-1.5-0.4c-0.7-0.2-1.4,0.2-1.6,0.9
		c-0.2,0.7,0.2,1.4,0.9,1.6l1.5,0.4C46.8,62.1,41.2,66.3,34.6,66.3z"/>
	<path fill="%23FE3341" d="M31,50.2c-0.2,1-0.1,2,0.4,2.8c0.5,0.9,1.3,1.5,2.3,1.7c0.3,0.1,0.6,0.1,0.9,0.1c0.2,0,0.5,0,0.7-0.1
		l0.4,0.7c0.2,0.4,0.7,0.6,1.1,0.6c0.2,0,0.5-0.1,0.7-0.2c0.6-0.4,0.8-1.1,0.4-1.8l-0.4-0.7c0.3-0.4,0.6-0.9,0.7-1.4
		c0.2-1,0.1-2-0.4-2.8l0,0c-0.8-1.4-2.4-2-3.9-1.7l-3.7-6.2c-0.4-0.6-1.1-0.8-1.8-0.4c-0.6,0.4-0.8,1.1-0.4,1.8l3.7,6.2
		C31.4,49.2,31.2,49.7,31,50.2z M34.9,50c0.3,0.1,0.6,0.3,0.7,0.5l0,0c0.2,0.3,0.2,0.6,0.1,0.9s-0.3,0.6-0.5,0.7
		c-0.3,0.2-0.6,0.2-0.9,0.1c-0.3-0.1-0.6-0.3-0.7-0.5c-0.2-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.3-0.6,0.5-0.7c0.2-0.1,0.4-0.2,0.6-0.2
		C34.7,49.9,34.8,49.9,34.9,50z"/>
	<path fill="%237A7A7A" d="M50.8,22.5c-5.7,0-11.2,2.7-14.6,7.2c-0.5,0-1-0.1-1.6-0.1c-0.5,0-1,0-1.6,0.1c-3.5-4.5-8.9-7.2-14.6-7.2
		C8.3,22.5,0,30.8,0,40.9c0,4.4,1.6,8.7,4.4,12c2.7,3.1,6.3,5.2,10.3,6.1c3.1,7.9,10.9,13.5,19.9,13.5S51.4,66.9,54.5,59
		c4-0.8,7.6-2.9,10.3-6.1c2.9-3.3,4.4-7.6,4.4-12C69.3,30.8,61,22.5,50.8,22.5z M6.4,51.3c-2.5-2.9-3.8-6.5-3.8-10.3
		c0-8.7,7.1-15.9,15.9-15.9c4.5,0,8.7,1.9,11.7,5.1c-0.5,0.1-1,0.2-1.5,0.4c-2.7-2.7-6.3-4.2-10.2-4.2c-3.9,0-7.5,1.5-10.3,4.3
		C5.4,33.4,3.9,37,3.9,40.9c0,3.2,1,6.2,2.9,8.8c1.7,2.2,4,4,6.7,4.9c0.1,0.5,0.2,1,0.3,1.5C11,55.2,8.4,53.6,6.4,51.3z M18.1,37.5
		l-2.5-3.6c-0.4-0.6-1.2-0.7-1.8-0.3c-0.6,0.4-0.7,1.2-0.3,1.8l2.4,3.4c-0.5,0.6-0.8,1.3-0.8,2.1c0,0.3,0,0.6,0.1,0.9
		c-1.3,2.8-2.1,5.9-2.1,9.2c0,0.2,0,0.4,0,0.6c-1.7-0.8-3.2-2-4.4-3.6c-0.7-0.9-1.2-1.9-1.6-2.9L8.1,45c0.7-0.2,1.1-0.9,0.9-1.6
		c-0.2-0.7-0.9-1.1-1.6-0.9l-0.9,0.2c-0.1-0.6-0.1-1.2-0.1-1.8c0-0.6,0-1.2,0.1-1.8l0.9,0.2c0.1,0,0.2,0,0.3,0
		c0.6,0,1.1-0.4,1.2-0.9c0.2-0.7-0.2-1.4-0.9-1.6l-0.9-0.2c0.4-1.1,1.1-2.2,1.8-3.2L9.7,34c0.2,0.2,0.6,0.4,0.9,0.4
		c0.3,0,0.7-0.1,0.9-0.4c0.5-0.5,0.5-1.3,0-1.8l-0.6-0.6c1-0.8,2-1.4,3.2-1.8l0.2,0.9c0.2,0.6,0.7,0.9,1.2,0.9c0.1,0,0.2,0,0.3,0
		c0.7-0.2,1.1-0.9,0.9-1.6l-0.2-0.9c0.6-0.1,1.2-0.1,1.8-0.1c0.6,0,1.2,0,1.8,0.1L20,30c-0.2,0.7,0.2,1.4,0.9,1.6c0.1,0,0.2,0,0.3,0
		c0.6,0,1.1-0.4,1.2-0.9l0.2-0.9c1.1,0.4,2.2,1,3.1,1.8C22.9,32.9,20.2,34.9,18.1,37.5z M34.6,69.9c-10.4,0-18.8-8.5-18.8-18.8
		s8.5-18.8,18.8-18.8s18.8,8.5,18.8,18.8S45,69.9,34.6,69.9z M46.5,29.8l0.2,0.9c0.2,0.6,0.7,0.9,1.2,0.9c0.1,0,0.2,0,0.3,0
		c0.7-0.2,1.1-0.9,0.9-1.6L49,29.1c0.6-0.1,1.2-0.1,1.8-0.1c0.6,0,1.2,0,1.8,0.1L52.5,30c-0.2,0.7,0.2,1.4,0.9,1.6
		c0.1,0,0.2,0,0.3,0c0.6,0,1.1-0.4,1.2-0.9l0.2-0.9c1.1,0.4,2.2,1.1,3.2,1.8l-0.6,0.6c-0.5,0.5-0.5,1.3,0,1.8
		c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4l0.6-0.6c0.8,1,1.4,2,1.8,3.2l-0.9,0.2c-0.7,0.2-1.1,0.9-0.9,1.6
		c0.2,0.6,0.7,0.9,1.2,0.9c0.1,0,0.2,0,0.3,0l0.9-0.2c0.1,0.6,0.1,1.2,0.1,1.8c0,0.6,0,1.2-0.1,1.8l-0.9-0.2
		c-0.7-0.2-1.4,0.2-1.6,0.9c-0.2,0.7,0.2,1.4,0.9,1.6l0.9,0.2c-0.4,1-0.9,2-1.6,2.9c-1.1,1.5-2.7,2.7-4.4,3.6c0-0.2,0-0.4,0-0.6
		c0-3.3-0.8-6.4-2.1-9.2c0.1-0.3,0.1-0.6,0.1-0.9c0-0.8-0.3-1.6-0.8-2.2l2.2-3.4c0.4-0.6,0.2-1.4-0.4-1.8c-0.6-0.4-1.4-0.2-1.8,0.4
		l-2.2,3.5c-2.1-2.5-4.7-4.5-7.7-5.9C44.3,30.8,45.4,30.2,46.5,29.8z M62.9,51.3c-2,2.3-4.6,4-7.4,4.9c0.1-0.5,0.2-1,0.3-1.5
		c2.6-0.9,5-2.7,6.7-4.9c1.9-2.5,2.9-5.6,2.9-8.8c0-3.9-1.5-7.5-4.2-10.2c0,0,0,0,0,0c0,0,0,0,0,0c-2.7-2.7-6.4-4.2-10.2-4.2
		c-3.8,0-7.4,1.5-10.2,4.2c-0.5-0.1-1-0.3-1.5-0.4c3-3.2,7.2-5.1,11.7-5.1c8.7,0,15.9,7.1,15.9,15.9C66.7,44.7,65.3,48.4,62.9,51.3z
		"/>
	<path fill="%237A7A7A" d="M38,60.1h-6.7c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3H38c0.7,0,1.3-0.6,1.3-1.3S38.7,60.1,38,60.1z"/></g></svg>')  no-repeat;
}
.svg-exartimata{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve"><g><g><path fill="%237A7A7A" d="M50.8,48.1l2.9-0.3c1.4-0.2,2.5-1.4,2.5-2.8c0-1.6-1.3-2.8-2.8-2.8h-2c-1.2,0-2.3,0.8-2.7,1.9l-2.8-6.1
			c-1-2.3-3.1-4-5.5-4.5c-0.1,0-0.2-0.1-0.4-0.1c-0.1-2.8-1.2-5.5-3.1-7.6c-2.1-2.3-5.1-3.7-8.2-3.9c-3.2-0.1-6.2,0.9-8.5,3.1
			c-2.3,2.1-3.7,5.1-3.9,8.2c0,0,0,0.1,0,0.1c-0.1,0-0.3,0.1-0.4,0.1c-2.4,0.6-4.5,2.2-5.5,4.5L7.5,44c-0.4-1.1-1.4-1.9-2.7-1.9h-2
			C1.3,42.1,0,43.4,0,45c0,1.4,1.1,2.6,2.5,2.8l2.9,0.3l-2.7,2.7c-1.3,1.3-2,3.2-1.7,5.1l0.9,6.6c0.2,1.7,1.1,3.2,2.4,4.2v3
			c0,1.8,1.5,3.2,3.2,3.2h5.4c1.8,0,3.2-1.5,3.2-3.2v-1.5h24v1.5c0,1.8,1.5,3.2,3.2,3.2h5.4c1.8,0,3.2-1.5,3.2-3.2v-3
			c1.3-1.1,2.1-2.6,2.4-4.2l0.9-6.6c0.3-1.9-0.4-3.8-1.7-5.1L50.8,48.1z M50.2,65.4c-0.8,0.5-1.7,0.8-2.6,0.8H8.5
			c-0.9,0-1.8-0.3-2.6-0.8c-1-0.7-1.7-1.7-1.9-2.9h48.1C51.9,63.7,51.2,64.7,50.2,65.4L50.2,65.4z M40.7,56.9l1.1,3.5h-1.5l-1.1-3.5
			H40.7z M37,56.9l1.1,3.5h-20l1.1-3.5H37z M15.8,60.4h-1.5l1.1-3.5h1.5L15.8,60.4z M12.1,60.4H3.7l-0.5-3.5h10L12.1,60.4z M44,60.4
			L43,56.9h10l-0.5,3.5H44z M50.6,45c0-0.4,0.3-0.7,0.7-0.7h2c0.4,0,0.7,0.3,0.7,0.7c0,0.3-0.3,0.6-0.6,0.7L50.6,46V45z M47.6,46.8
			h-3.5L38,40.2c0.3-0.5,0.6-1.1,0.9-1.7c1.4,0.1,2.5,0.2,3.2,0.3c0.8,0.1,1.5,0.4,2.1,0.7L47.6,46.8z M36.1,42.5
			c0.2-0.2,0.4-0.4,0.6-0.6l8.2,9c0.3,0.4,0.3,0.9-0.1,1.3c-0.4,0.3-0.9,0.3-1.3-0.1l-8.2-9C35.6,42.9,35.8,42.7,36.1,42.5
			L36.1,42.5z M42.3,36.7c-0.7-0.1-1.6-0.2-2.7-0.3c0.1-0.3,0.1-0.6,0.2-0.9c0,0,0,0,0,0C40.7,35.7,41.6,36.1,42.3,36.7L42.3,36.7z
			 M18.3,33.3c0.1-2.6,1.2-5,3.2-6.7c1.8-1.6,4.1-2.5,6.5-2.5c0.2,0,0.3,0,0.5,0c2.6,0.1,5,1.2,6.7,3.2c3.6,4,3.3,10.1-0.6,13.7
			c-1.9,1.8-4.4,2.6-7,2.5c-2.6-0.1-5-1.2-6.7-3.2C19.1,38.4,18.2,35.9,18.3,33.3L18.3,33.3z M16.3,35.5
			C16.3,35.5,16.3,35.5,16.3,35.5c0,0.3,0.1,0.6,0.2,0.9c-1.1,0.1-2,0.2-2.7,0.3C14.5,36.1,15.4,35.7,16.3,35.5L16.3,35.5z
			 M11.9,39.6c0.7-0.3,1.4-0.6,2.1-0.7c0.7-0.1,1.8-0.3,3.2-0.4c0.5,1.2,1.2,2.3,2.1,3.2c2.1,2.3,5.1,3.7,8.2,3.9c0.2,0,0.4,0,0.6,0
			c1.9,0,3.8-0.5,5.5-1.3l2.3,2.6H8.6L11.9,39.6z M2.1,45c0-0.4,0.3-0.7,0.7-0.7h2c0.4,0,0.7,0.3,0.7,0.7v1l-2.8-0.3
			C2.4,45.6,2.1,45.3,2.1,45L2.1,45z M7.6,49h30.3l4.2,4.6c0.6,0.7,1.4,1,2.2,1c0.7,0,1.5-0.3,2-0.8c1.2-1.1,1.3-3.1,0.2-4.3
			L46.1,49h2.5l3.4,3.4c0.6,0.6,1,1.5,1.1,2.4H3c0.1-0.9,0.5-1.7,1.1-2.4L7.6,49z M13.9,69.8c0,0.6-0.5,1.1-1.1,1.1H7.5
			c-0.6,0-1.1-0.5-1.1-1.1V68c0.7,0.2,1.4,0.3,2.1,0.3h5.4V69.8z M48.7,70.9h-5.4c-0.6,0-1.1-0.5-1.1-1.1v-1.5h5.4
			c0.7,0,1.4-0.1,2.1-0.3v1.8C49.7,70.4,49.3,70.9,48.7,70.9L48.7,70.9z M48.7,70.9"/>
	</g>
	<path fill="%237A7A7A" d="M27.7,42.5c0.1,0,0.3,0,0.4,0c2.2,0,4.3-0.8,5.9-2.3c3.6-3.3,3.8-8.8,0.6-12.4c-1.6-1.7-3.7-2.7-6.1-2.8
		c-2.3-0.1-4.6,0.7-6.3,2.3c-3.6,3.3-3.8,8.8-0.6,12.4C23.2,41.4,25.3,42.4,27.7,42.5L27.7,42.5z M23.6,28.9
		c1.2-1.1,2.8-1.7,4.4-1.7c0.1,0,0.2,0,0.3,0c1.8,0.1,3.4,0.8,4.6,2.2c2.5,2.7,2.3,6.9-0.4,9.3c-1.3,1.2-3,1.8-4.8,1.7
		c-1.8-0.1-3.4-0.8-4.6-2.2C20.7,35.5,20.9,31.3,23.6,28.9L23.6,28.9z M23.6,28.9"/>
	<path fill="%23FE3341" d="M30.4,36.3c-0.4,0.4-0.5,1.1-0.1,1.5c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.7-0.3c1.1-1,1.8-2.4,1.8-3.9
		c0.1-1.5-0.5-3-1.5-4.1c-0.4-0.4-1.1-0.5-1.5-0.1c-0.4,0.4-0.5,1.1-0.1,1.5c0.6,0.7,1,1.6,0.9,2.5C31.5,34.8,31.1,35.7,30.4,36.3
		L30.4,36.3z M30.4,36.3"/></g></svg>') no-repeat;
}
.svg-gnisiaaxesouar{
  background:  url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve"><g><g>
		<polygon fill="%23FE3341" points="50.2,36.9 50.2,47.9 54.7,47.5 62,48.4 		"/>
		<path fill="%237A7A7A" d="M67.9,34.1c-1.5-1.7-3.7-2.7-6.1-2.7H39.8c-5.4,0-10.6,2.1-14.5,5.9L0.4,61.4c-0.4,0.4-0.5,0.9-0.3,1.4
			c0.2,0.5,0.7,0.8,1.2,0.8h64.5c0.7,0,1.2-0.5,1.3-1.2l2.8-22C70.2,38.2,69.5,35.8,67.9,34.1L67.9,34.1z M10,61l8.7-8.4V61H10z
			 M21.3,61V50l8.5-8.2c2.7-2.6,6.2-4,10-4h9.5v10.7c0,0.4,0.2,0.9,0.6,1.1c0.4,0.2,0.9,0.3,1.3,0.1c2.4-1.1,5.1-1.3,7.5-0.5
			l3.5,1.1L60.8,61H21.3z M63.5,39.6l-0.9,7.1l-8.9-8.9h8.2c0.5,0,0.9,0.2,1.2,0.6C63.4,38.7,63.6,39.2,63.5,39.6L63.5,39.6z
			 M51.9,39.7l6.8,6.8c-2.3-0.6-4.6-0.5-6.8,0.1V39.7z M67.3,40.1L64.6,61h-1.2l2.7-21c0.2-1.2-0.2-2.4-1-3.4
			c-0.8-0.9-2-1.4-3.2-1.4H39.8c-4.4,0-8.6,1.7-11.8,4.8L6.2,61H4.6l22.6-21.9C30.6,35.8,35,34,39.8,34h22.1c1.6,0,3,0.7,4.1,1.8
			C67,37,67.5,38.6,67.3,40.1L67.3,40.1z M67.3,40.1"/></g></g></svg>') no-repeat;
}
.svg-interior{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve">
<circle fill="%23FE3341" cx="16.4" cy="67.4" r="3.9"/><g>
	<path fill="%237A7A7A" d="M45.2,64.4c-1.9-1.7-4.5-2.4-7-2l-12.8,2l-3.6-15.2c-0.9-3.9-3-7.5-5.9-10.2c-1.7-1.6-3.7-3-5.8-3.9
		c2.2-1.6,3.3-4.4,2.6-7.2l-1.5-5.4c-1-3.5-4.6-5.6-8.2-4.6c-2.1,0.6-3.4,2.8-2.8,4.9l3.6,13c0,0.1,7,29.3,7,29.3
		c-0.3,0.7-0.5,1.5-0.5,2.3c0,1.6,0.6,3,1.6,4.1v0.8c0,3,2.4,5.4,5.4,5.4h25.7c3,0,5.4-2.4,5.4-5.4V71
		C48.2,68.5,47.1,66.1,45.2,64.4z M3.7,20.6c0.3-0.1,0.7-0.1,1-0.1c1.6,0,3.1,1.1,3.6,2.7l1.5,5.4c0.5,2-0.6,4-2.6,4.6l-1,0.3
		L3,21.9C2.8,21.3,3.2,20.7,3.7,20.6z M13.9,41.1c2.5,2.4,4.2,5.4,5,8.8l3.6,15L22,64.9c-0.9-2.2-3.1-3.8-5.6-3.8
		c-1.2,0-2.3,0.4-3.3,1L7.1,37C9.6,37.8,11.9,39.2,13.9,41.1z M13.2,67.2c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1
		s-1.4,3.1-3.1,3.1C14.7,70.4,13.2,69,13.2,67.2z M45.3,72.1c0,1.3-1.1,2.4-2.4,2.4H17.2c-1,0-1.8-0.6-2.2-1.4
		c0.4,0.1,0.9,0.2,1.4,0.2c3.2,0,5.8-2.4,6.1-5.5l16.2-2.5c1.7-0.3,3.4,0.2,4.6,1.3c1.3,1.1,2,2.7,2,4.4L45.3,72.1L45.3,72.1z"/></g></svg>') no-repeat;
}
.svg-mechanicalparts{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve">
<circle fill="%23FE3341" cx="21.2" cy="25.9" r="2.9"/>
<path fill="%237A7A7A" d="M35.5,34.5h4.7c1.4,0,2.5-1.1,2.5-2.5V20c0-1.4-1.1-2.5-2.5-2.5h-4.7c-1.4,0-2.5,1.1-2.5,2.5v4.9h-7.6
	c-0.5-1.8-2.1-3.1-4.1-3.1s-3.6,1.3-4.1,3.1H9.6V20c0-1.4-1.1-2.5-2.5-2.5H2.5C1.1,17.5,0,18.6,0,20L0,32c0,1.4,1.1,2.5,2.5,2.5h4.7
	c1.4,0,2.5-1.1,2.5-2.5v-4.9h7.6c0.4,1.4,1.5,2.5,2.9,2.9v19.3c-1.8,0.7-3.1,2.8-3.1,5.4c0,2.6,1.3,4.8,3.1,5.4v4.7
	c-1.4,0.4-2.5,1.5-2.9,2.9H9.6V63c0-1.4-1.1-2.5-2.5-2.5H2.5C1.1,60.5,0,61.6,0,63L0,75c0,1.4,1.1,2.5,2.5,2.5h4.7
	c1.4,0,2.5-1.1,2.5-2.5v-4.9h7.6c0.5,1.8,2.1,3.1,4.1,3.1s3.6-1.3,4.1-3.1H33V75c0,1.4,1.1,2.5,2.5,2.5h4.7c1.4,0,2.5-1.1,2.5-2.5
	V63c0-1.4-1.1-2.5-2.5-2.5h-4.7c-1.4,0-2.5,1.1-2.5,2.5v4.9h-7.6c-0.4-1.4-1.5-2.5-2.9-2.9v-4.7c1.8-0.7,3.1-2.8,3.1-5.4
	c0-2.6-1.3-4.8-3.1-5.4V30.1c1.4-0.4,2.5-1.5,2.9-2.9H33V32C33,33.4,34.1,34.5,35.5,34.5z M7.3,32c0,0.1-0.1,0.1-0.1,0.1H2.5
	c-0.1,0-0.1-0.1-0.1-0.1V20c0-0.1,0.1-0.1,0.1-0.1h4.7c0.1,0,0.1,0.1,0.1,0.1L7.3,32L7.3,32z M7.3,75c0,0.1-0.1,0.1-0.1,0.1H2.5
	c-0.1,0-0.1-0.1-0.1-0.1V63c0-0.1,0.1-0.1,0.1-0.1h4.7c0.1,0,0.1,0.1,0.1,0.1L7.3,75L7.3,75z M35.3,63c0-0.1,0.1-0.1,0.1-0.1h4.7
	c0.1,0,0.1,0.1,0.1,0.1V75c0,0.1-0.1,0.1-0.1,0.1h-4.7c-0.1,0-0.1-0.1-0.1-0.1V63z M23.2,69c0,1.1-0.9,1.9-1.9,1.9
	c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-1.9C22.4,67.1,23.2,67.9,23.2,69z M21.3,58.1c-0.9,0-1.9-1.4-1.9-3.3c0-2,1-3.3,1.9-3.3
	c0.9,0,1.9,1.4,1.9,3.3C23.2,56.7,22.2,58.1,21.3,58.1z M21.3,27.9c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-1.9
	c1.1,0,1.9,0.9,1.9,1.9C23.2,27.1,22.4,27.9,21.3,27.9z M35.3,20c0-0.1,0.1-0.1,0.1-0.1h4.7c0.1,0,0.1,0.1,0.1,0.1V32
	c0,0.1-0.1,0.1-0.1,0.1h-4.7c-0.1,0-0.1-0.1-0.1-0.1V20z"/></svg>') no-repeat;
}
.svg-sasman{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve"><g>
	<path fill="%23FE3341" d="M45.7,40.1c-6,0-10.9,4.9-10.9,10.9c0,6,4.9,10.9,10.9,10.9c6,0,10.9-4.9,10.9-10.9
		C56.7,45,51.8,40.1,45.7,40.1z M45.1,58c-3.6,0-6.6-2.9-6.6-6.6c0-3.6,2.9-6.6,6.6-6.6c3.6,0,6.6,2.9,6.6,6.6
		C51.7,55.1,48.8,58,45.1,58z"/>
	<g>
		<path fill="%237A7A7A" d="M8.1,70.4c0,2.1,1.7,3.7,3.7,3.7s3.7-1.7,3.7-3.7s-1.7-3.7-3.7-3.7S8.1,68.3,8.1,70.4z M13.3,70.4
			c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5C12.7,68.9,13.3,69.6,13.3,70.4z"/>
		<path fill="%237A7A7A" d="M11.8,29.3c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4C15.2,30.8,13.7,29.3,11.8,29.3z
			 M11.8,33.8c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1S12.5,33.8,11.8,33.8z"/>
		<path fill="%237A7A7A" d="M22.6,17.5H1.1C0.5,17.5,0,18,0,18.6V42c0,0.6,0.5,1.1,1.1,1.1h4.8c0.6,0,1.1-0.5,1.1-1.1
			c0-1.3,0.8-2.4,1.9-2.9L8.3,62.5c0,1.2-1,2.1-2.1,2.1c-0.6,0-1.1,0.5-1.1,1.1v5c0,3.7,3,6.8,6.8,6.8s6.8-3,6.8-6.8v-5
			c0-0.6-0.5-1.1-1.1-1.1c-1.2,0-2.1-0.9-2.1-2.1l-0.6-23.4c1.1,0.5,1.9,1.6,1.9,2.9c0,0.6,0.5,1.1,1.1,1.1h4.8
			c0.6,0,1.1-0.5,1.1-1.1V18.6C23.7,18,23.2,17.5,22.6,17.5z M21.4,19.8V21H2.3v-1.2H21.4z M21.4,23.2v1.4H2.3v-1.4H21.4z
			 M16.4,66.7v4c0,2.5-2,4.5-4.5,4.5s-4.5-2-4.5-4.5v-4c1.9-0.5,3.2-2.1,3.3-4.1l0.6-23.7h1.2l0.6,23.7c0,0,0,0,0,0
			C13.2,64.5,14.5,66.2,16.4,66.7z M18.8,40.9c-0.5-2.5-2.7-4.3-5.3-4.3h-3.2c-2.6,0-4.8,1.8-5.3,4.3H2.3v-14h19.2v14H18.8z"/>
	</g>
	<g>
		<path fill="%237A7A7A" d="M45.1,42.8c-4.7,0-8.4,3.8-8.4,8.4s3.8,8.4,8.4,8.4s8.4-3.8,8.4-8.4S49.8,42.8,45.1,42.8z M45.1,57.3
			c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S48.5,57.3,45.1,57.3z"/>
		<path fill="%237A7A7A" d="M45.1,39c-6.7,0-12.2,5.5-12.2,12.2s5.5,12.2,12.2,12.2S57.4,58,57.4,51.2S51.9,39,45.1,39z M45.1,61.1
			c-5.4,0-9.8-4.4-9.8-9.8s4.4-9.8,9.8-9.8s9.8,4.4,9.8,9.8S50.5,61.1,45.1,61.1z"/>
		<path fill="%237A7A7A" d="M78.1,31.8h-2.7c-0.3-0.9-0.6-1.7-1.1-2.5l1.9-1.9c0.5-0.5,0.5-1.2,0-1.7l-5.1-5.1c-0.5-0.5-1.2-0.5-1.7,0
			l-1.9,1.9c-0.8-0.4-1.7-0.8-2.5-1.1v-2.7c0-0.7-0.5-1.2-1.2-1.2h-7.2c-0.7,0-1.2,0.5-1.2,1.2v2.7c-0.9,0.3-1.7,0.6-2.5,1.1
			l-1.9-1.9c-0.5-0.5-1.2-0.5-1.7,0l-4.8,4.8h-2.8c-0.7,0-1.2,0.5-1.2,1.2v3.2c-0.6,0.1-1.2,0.3-1.8,0.5l-1.6-2.8
			c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6,0-0.9,0.1l-6.2,3.6c-0.3,0.2-0.5,0.4-0.6,0.7c-0.1,0.3,0,0.6,0.1,0.9l1.6,2.8
			c-0.5,0.4-0.9,0.9-1.3,1.3l-2.8-1.6c-0.3-0.2-0.6-0.2-0.9-0.1c-0.3,0.1-0.6,0.3-0.7,0.6l-3.6,6.2c-0.3,0.6-0.1,1.3,0.4,1.6
			l2.8,1.6c-0.2,0.6-0.3,1.2-0.5,1.8h-3.2c-0.7,0-1.2,0.5-1.2,1.2v7.1c0,0.7,0.5,1.2,1.2,1.2h3.2c0.1,0.6,0.3,1.2,0.5,1.8l-2.8,1.6
			c-0.3,0.2-0.5,0.4-0.6,0.7c-0.1,0.3,0,0.6,0.1,0.9l3.6,6.2c0.3,0.6,1.1,0.8,1.6,0.4l2.8-1.6c0.4,0.5,0.9,0.9,1.3,1.3l-1.6,2.8
			c-0.2,0.3-0.2,0.6-0.1,0.9c0.1,0.3,0.3,0.6,0.6,0.7l6.2,3.6c0.6,0.3,1.3,0.1,1.6-0.4l1.6-2.8c0.6,0.2,1.2,0.3,1.8,0.5v3.2
			c0,0.7,0.5,1.2,1.2,1.2h7.1c0.7,0,1.2-0.5,1.2-1.2v-3.2c0.6-0.1,1.2-0.3,1.8-0.5l1.6,2.8c0.3,0.6,1.1,0.8,1.6,0.4l6.2-3.6
			c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0-0.6-0.1-0.9L60,67.4c0.5-0.4,0.9-0.9,1.3-1.3l2.8,1.6c0.6,0.3,1.3,0.1,1.6-0.4l3.6-6.2
			c0.2-0.3,0.2-0.6,0.1-0.9c-0.1-0.3-0.3-0.6-0.6-0.7l-2.8-1.6c0.2-0.6,0.3-1.2,0.5-1.8h3.2c0.7,0,1.2-0.5,1.2-1.2v-2
			c0,0,0.1-0.1,0.1-0.1l5.1-5.1c0.5-0.5,0.5-1.2,0-1.7L74.3,44c0.4-0.8,0.8-1.7,1.1-2.5h2.7c0.7,0,1.2-0.5,1.2-1.2V33
			C79.3,32.4,78.7,31.8,78.1,31.8z M68.6,53.6h-3c-0.6,0-1.1,0.4-1.2,1c-0.2,1.1-0.5,2.3-0.9,3.4c-0.2,0.6,0,1.2,0.5,1.5l2.6,1.5
			L64.3,65l-2.6-1.5c-0.5-0.3-1.2-0.2-1.5,0.3c-0.7,0.9-1.6,1.7-2.5,2.5c-0.4,0.4-0.6,1-0.3,1.5l1.5,2.6l-4.1,2.4l-1.5-2.6
			c-0.3-0.5-0.9-0.7-1.5-0.5c-1.1,0.4-2.2,0.7-3.4,0.9c-0.6,0.1-1,0.6-1,1.2v3h-4.7v-3c0-0.6-0.4-1.1-1-1.2
			c-1.1-0.2-2.3-0.5-3.4-0.9c-0.6-0.2-1.2,0-1.5,0.5l-1.5,2.6l-4.1-2.4l1.5-2.6c0.3-0.5,0.2-1.2-0.3-1.5c-0.9-0.7-1.7-1.6-2.5-2.5
			c-0.4-0.4-1-0.6-1.5-0.3L26,65l-2.4-4.1l2.6-1.5c0.5-0.3,0.7-0.9,0.5-1.5c-0.4-1.1-0.7-2.2-0.9-3.4c-0.1-0.6-0.6-1-1.2-1h-3v-4.7
			h3c0.6,0,1.1-0.4,1.2-1c0.2-1.1,0.5-2.3,0.9-3.4c0.2-0.6,0-1.2-0.5-1.5l-2.6-1.5l2.4-4.1l2.6,1.5c0.5,0.3,1.2,0.2,1.5-0.3
			c0.7-0.9,1.6-1.7,2.5-2.5c0.4-0.4,0.6-1,0.3-1.5l-1.5-2.6l4.1-2.4l1.5,2.6c0.3,0.5,0.9,0.7,1.5,0.5c1.1-0.4,2.2-0.7,3.4-0.9
			c0.6-0.1,1-0.6,1-1.2v-3h4.7v3c0,0.6,0.4,1.1,1,1.2c1.1,0.2,2.3,0.5,3.4,0.9c0.6,0.2,1.2,0,1.5-0.5l1.5-2.6l4.1,2.4l-1.5,2.6
			c-0.3,0.5-0.2,1.2,0.3,1.5c0.9,0.7,1.7,1.6,2.5,2.5c0.4,0.4,1,0.6,1.5,0.3l2.6-1.5l2.4,4.1L64,43c-0.5,0.3-0.7,0.9-0.5,1.5
			c0.4,1.1,0.7,2.2,0.9,3.4c0.1,0.6,0.6,1,1.2,1h3V53.6z M76.8,39.1h-2.4c-0.6,0-1,0.4-1.2,0.9c-0.3,1.3-0.8,2.5-1.5,3.6
			c-0.3,0.5-0.2,1.1,0.2,1.5l1.7,1.7L71,49.4v-1.8c0-0.7-0.5-1.2-1.2-1.2h-3.2c-0.1-0.6-0.3-1.2-0.5-1.8l2.8-1.6
			c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0-0.6-0.1-0.9l-3.6-6.2c-0.3-0.6-1.1-0.8-1.6-0.4l-2.8,1.6c-0.4-0.5-0.9-0.9-1.3-1.3l1.6-2.8
			c0.2-0.3,0.2-0.6,0.1-0.9c-0.1-0.3-0.3-0.6-0.6-0.7L55,27c-0.3-0.2-0.6-0.2-0.9-0.1c-0.3,0.1-0.6,0.3-0.7,0.6l-1.6,2.8
			c-0.6-0.2-1.2-0.3-1.8-0.5v-3.2c0-0.7-0.5-1.2-1.2-1.2h-1l2.3-2.3l1.7,1.7c0.4,0.4,1,0.5,1.5,0.2c1.1-0.7,2.4-1.2,3.6-1.5
			c0.5-0.1,0.9-0.6,0.9-1.2v-2.4h4.8v2.4c0,0.6,0.4,1,0.9,1.2c1.3,0.3,2.5,0.8,3.6,1.5c0.5,0.3,1.1,0.2,1.5-0.2l1.7-1.7l3.4,3.4
			L72,28.2c-0.4,0.4-0.5,1-0.2,1.5c0.7,1.1,1.2,2.4,1.5,3.6c0.1,0.5,0.6,0.9,1.2,0.9h2.4L76.8,39.1z"/></g></g></svg>') no-repeat;
}
.svg-service{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve"><g>
	<circle fill="%23FE3341" cx="68.6" cy="33.6" r="4.5"/>
	<polygon fill="%23FE3341" points="26.2,55.7 16.5,65.8 18.5,68 28.9,58.4 	"/>
	<g><path fill="%237A7A7A" d="M40.1,42.3c-3.7,0-6.7,3-6.7,6.7c0,3.7,3,6.7,6.7,6.7c3.7,0,6.7-3,6.7-6.7C46.7,45.3,43.7,42.3,40.1,42.3z
			 M40.1,53.8c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8c2.7,0,4.8,2.2,4.8,4.8C44.9,51.7,42.7,53.8,40.1,53.8z"/>
		<path fill="%237A7A7A" d="M40.1,46.6c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4
			C42.4,47.7,41.4,46.6,40.1,46.6z M40.1,49.6c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6
			C40.6,49.3,40.4,49.6,40.1,49.6z"/>
		<path fill="%237A7A7A" d="M45.4,25.3c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4S43.6,25.3,45.4,25.3z
			 M45.4,20.3c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6S44.6,20.3,45.4,20.3z"/>
		<circle fill="%237A7A7A" cx="68.8" cy="33.6" r="1.4"/>
		<path fill="%237A7A7A" d="M71.9,38.6c1.7-1,2.8-2.9,2.8-5c0-2.1-1.1-3.9-2.7-4.9L49.2,15.4l0,0c-1.1-0.6-2.4-1-3.8-1
			c-3.3,0-6.1,2.1-7.1,5.1c0,0.1-7.3,27.2-7.4,27.4l0,0l0,0c-0.2,0.7-0.2,1.4-0.2,2.1c0,5.2,4.2,9.4,9.4,9.4c1.8,0,3.4-0.5,4.8-1.4
			C45,56.9,71.8,38.6,71.9,38.6z M67.5,39.3L48.9,52c0.3-1,0.5-2,0.5-3c0-5.2-4.2-9.4-9.4-9.4c-2.2,0-4.2,0.8-5.8,2l4.4-16.5
			c1.2,2.5,3.8,4.2,6.8,4.2c4.1,0,7.5-3.4,7.5-7.5c0-0.9-0.1-1.7-0.4-2.4L67.2,28c-2.4,0.7-4.2,3-4.2,5.6
			C63,36.4,64.9,38.7,67.5,39.3z M68.8,37.6c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C72.8,35.8,71,37.6,68.8,37.6z M45.4,16.2
			c3.1,0,5.7,2.6,5.7,5.7s-2.6,5.7-5.7,5.7s-5.7-2.6-5.7-5.7S42.3,16.2,45.4,16.2z M32.5,49c0-4.2,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5
			s-3.4,7.5-7.5,7.5S32.5,53.1,32.5,49z"/>
	</g>
	<path fill="%237A7A7A" d="M0,42.6c0-0.2,0.1-0.5,0.3-0.7l2.6-2.6c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3l3.3,3.3
		c0.5-0.2,1-0.3,1.5-0.3c1.1,0,2.2,0.4,3,1.2c0.1,0.1,0.1,0.1,0.2,0.2l0.5,1c0.3,0.5,0.7,0.9,1.2,1.2c0.9,0.4,1.5,1.1,2,2
		c0.3,0.5,0.7,0.9,1.2,1.2c0.8,0.4,1.5,0.9,2.1,1.5l5.5,5.5l1.4-1.4c0.4-0.4,1-0.4,1.3,0l2.7,2.7c0.4,0.4,0.4,1,0,1.3l-0.8,0.8
		l0.7,0.7l0.8-0.8c0.2-0.2,0.4-0.3,0.7-0.3c0.2,0,0.5,0.1,0.7,0.3l3.7,3.7c0.4,0.4,0.4,1,0,1.3L35,65.4l8,8c1.4,1.4,1.4,3.7,0,5.1
		l-1.8,1.8c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.3l1.8-1.8c0.7-0.7,0.7-1.8,0-2.5l-1-1l-2.3,2.3l0.7,0.7
		c0.4,0.4,0.4,1,0,1.3c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3L37,77.4l-3,3c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.5-0.1-0.7-0.3
		L26.4,74l-0.8,0.8c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3l-3.7-3.7c-0.4-0.4-0.4-1,0-1.3l0.8-0.8l-0.7-0.7l-0.8,0.8
		c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3l-2.7-2.7c-0.4-0.4-0.4-1,0-1.3l1.4-1.4l-5.5-5.5c-0.6-0.6-1.1-1.3-1.5-2.1
		C9.9,55.6,9.5,55.2,9,55c-0.9-0.4-1.5-1.1-2-2c-0.3-0.5-0.7-0.9-1.2-1.2l-1-0.5c-0.1,0-0.2-0.1-0.2-0.2c-0.8-0.8-1.2-1.9-1.2-3
		c0-0.5,0.1-1.1,0.3-1.5l-3.3-3.3C0.1,43.1,0,42.8,0,42.6z M33.3,78.4l6-6l-5.7-5.7l-6,6L33.3,78.4z M24.9,72.9l8.9-8.9l-2.4-2.4
		l-8.9,8.9L24.9,72.9z M22.6,67.7l6-6L27.9,61l-6,6L22.6,67.7z M19.1,67.1l8.9-8.9l-1.4-1.4l-8.9,8.9L19.1,67.1z M7.3,45l-1.4,1.4
		c-0.5,0.5-0.7,1.1-0.7,1.7c0,0.5,0.2,1,0.5,1.5l4.8-4.8c-0.4-0.3-0.9-0.5-1.5-0.5C8.4,44.3,7.8,44.5,7.3,45z M14.4,49.3l-4.2,4.2
		c0.7,0.4,1.2,1,1.6,1.7l4.3-4.3C15.4,50.5,14.9,49.9,14.4,49.3z M18.5,62.4l4.8-4.8l-5.5-5.5c-0.1-0.1-0.1-0.1-0.2-0.2l-4.8,4.8
		c0.1,0.1,0.1,0.1,0.2,0.2L18.5,62.4z M8.7,52.1c0,0.1,0.1,0.1,0.1,0.2l4.5-4.5c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.3-1.2-0.8-1.6-1.3
		l-4.1,4.1C7.9,51,8.3,51.5,8.7,52.1z M4.7,45l1.2-1.2l-2.4-2.4l-1.2,1.2L4.7,45z"/></g></svg>') no-repeat;
}
.svg-simblektis{
  background: url('data:image/svg+xml;utf8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 210 95" enable-background="new 0 0 210 95" xml:space="preserve">
<rect x="18.2" y="62.3" fill="%23FE3341" width="7.8" height="6.1"/><g>
	<path fill="%237A7A7A" d="M56.3,70c-2.9,0-5.2-2.3-5.2-5.2V52.7h-6.6v12.1c0,2.9-2.3,5.2-5.2,5.2s-5.2-2.3-5.2-5.2V52.7h-6.6v12.1
		c0,2.9-2.3,5.2-5.2,5.2S17,67.7,17,64.8V52.7H5.2c-2.9,0-5.2-2.3-5.2-5.2V30.2C0,27.3,2.3,25,5.2,25s5.2,2.3,5.2,5.2v12.1H17V30.2
		c0-2.9,2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2v12.1h6.6V30.2c0-2.9,2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2v12.1h6.6V30.2c0-2.9,2.3-5.2,5.2-5.2
		c2.9,0,5.2,2.3,5.2,5.2v34.6C61.5,67.7,59.2,70,56.3,70z M43.1,50h9.3c0.7,0,1.3,0.6,1.3,1.3v13.4c0,1.4,1.1,2.5,2.5,2.5
		c1.4,0,2.5-1.1,2.5-2.5V30.2c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5v13.4c0,0.7-0.6,1.3-1.3,1.3h-9.3
		c-0.7,0-1.3-0.6-1.3-1.3V30.2c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5v13.4c0,0.7-0.6,1.3-1.3,1.3h-9.3
		c-0.7,0-1.3-0.6-1.3-1.3V30.2c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5v13.4c0,0.7-0.6,1.3-1.3,1.3H9.1c-0.7,0-1.3-0.6-1.3-1.3V30.2
		c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5v17.3c0,1.4,1.1,2.5,2.5,2.5h13.2c0.7,0,1.3,0.6,1.3,1.3v13.4c0,1.4,1.1,2.5,2.5,2.5
		s2.5-1.1,2.5-2.5V51.4c0-0.7,0.6-1.3,1.3-1.3h9.3c0.7,0,1.3,0.6,1.3,1.3v13.4c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5V51.4
		C41.8,50.6,42.4,50,43.1,50z"/></g></svg>') no-repeat;
}
